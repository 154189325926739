.user-info {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 800;
}

input[type='text']:read-only,
textarea:read-only {
  background-color: #f4f4f4;
  /* Background color */
  color: #999;
  /* Text color */
  border: 1px solid #ccc;
  /* Border */
  cursor: not-allowed;
  /* Cursor style */
}

.required {
  color: #e54643;
}

.required:after {
  content: "*";
  color: #e54643;
}

.active {
  background-color: gainsboro;
}

.react-datepicker__input-container input {
  border-radius: 10px !important;
}

.item-table-label {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f1f1f1;
}

.bg-mild-grey {
  background-color: #f9f9fb;
}

.no-border {
  border: none !important;
}

.drawer-image {
  justify-content: center;
  display: flex;
  width: 100%;
  position: relative;
}

.drawer-image img {
  height: 20% !important;
}

.input-prefix-amount {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 9px 10px;
  background-color: #f9f9fb;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

body #root .light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}

body #root .dark-mode {
  /* background-color: #2b2b37; */
  background-color: #181d1f;
  color: #fff;
  height: 100vh;
  overflow-y: auto;

}

ol,
ul {
  padding-left: 0px !important;
}

.setting_placeholder {
  background-image: url('../public/placeholder.svg');
  border-bottom: 1px solid #f1f1f1;
}

.setting-placeholder_dark {
  background-image: url('../public/placeholder.svg');
  border-bottom: 1px solid #f1f1f1;
}

.narrow-tr{
  background-color: #c6cbc940;
}